import { createSelector } from 'reselect'

export const selectShop = state => state.shop

export const selectCollections = createSelector(
    selectShop,
    shop => shop.collections
)

export const selectCollectionsForPreview = createSelector(
    selectCollections,
    collections => collections ? Object.keys(collections).map(key => collections[key]) : []
)

export const selectCollection = collectionUrlParam => createSelector(
    selectCollections,
    collections => collections ? collections[collectionUrlParam] : null
)

export const selectIsCollectionsLoaded = createSelector(
    selectShop,
    shop => !!shop.collections
)