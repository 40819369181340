import React from 'react'
import './SigInAndSignUp.style.scss'
import SignIn from '../../components/SignIn'
import SignUp from '../../components/SignUp'

const SignInAndSignUp = () => <div className="sign-in-and-sign-up">
    <SignIn/>
    <SignUp/>
</div>

export default SignInAndSignUp
