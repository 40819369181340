import React from 'react'
import Directory from '../../components/Directory'
import {HomepageContainer} from  './Homepage.styles'

const Homepage = () => {
    return (
        <HomepageContainer>
            <Directory/>
        </HomepageContainer>
    )
}

export default Homepage