import cartActionTypes from './cart.types'

export const toggleCartHidden = () => ({
    type: cartActionTypes.TOGGLE_CART_HIDDEN
})

export const addItem = item => ({
    type: cartActionTypes.ADD_ITEM,
    payload: item
})

export const removeItem = item => ({
    type: cartActionTypes.REMOVE_ITEM,
    payload: item
})

export const clearItemFromCart = itemId => ({
    type: cartActionTypes.CLEAR_ITEM_FROM_CART,
    payload: itemId
})

export const clearAllItemsFromCart = () => ({
    type: cartActionTypes.CLEAR_ALL_ITEMS_FROM_CART
})